import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import BasicList from "../components/BasicList"
import Container from "../components/Container"
import DotSection from "../components/DotSection"
import Hero from "../components/Hero"
import HeroLead from "../components/HeroLead"
import HeroTitle from "../components/HeroTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import SubTitle from "../components/SubTitle"
import Text from "../components/Text"
import Title from "../components/Title"
import links from "../content/links"
import metaImg from "../images/luottokorttipuhelin.svg"

export default (): JSX.Element => {
  return (
    <Layout>
      <SEO
        title="Ohjelmistokehitys ratkaisut saat meiltä"
        description="Teemme ja suunnittelemme tietojärjestelmiä. Meidän ammattitaitomme perustuu moderneihin ja johtaviin ratkaisuihin ohjelmistosuunnittelussa."
        pageImage={metaImg}
        pageUrl={links.softwareSolutions}
      />

      <section id="hero">
        <Hero>
          <HeroTitle>
            <h1>
              <span className="block">Ohjelmistokehitys</span>
              <span className="block text-indigo-400">ratkaisut</span>
            </h1>
          </HeroTitle>
          <HeroLead>Teemme ohjelmistosuunnittelua asiakkaan ehdoilla.</HeroLead>
        </Hero>
      </section>

      <section id="software">
        <DotSection dotPosition="top-right">
          <div className="relative lg:grid md:grid-cols-2 md:gap-x-8">
            <div>
              <Title tag="h2">Ohjelmistokehitys</Title>
              <Text>
                Teemme ja suunnittelemme tietojärjestelmiä, eli ohjelmistoja.
                Ohjelmistoja pystytään ohjaamaan esimerkiksi tietokoneiden,
                tablettien, älypuhelimien, radioiden ja vaikkapa autojen avulla.
                Yhteiskuntamme on digitalisoitunut ja tietotekniikka sekä
                ohjelmistokehitys on kasvanut ja avannut yrityksille ihan uudet
                markkinaraot. Tämän ansiosta ohjelmistokehitys on muuttunut
                valtavasti ja enään mielikuvitus on sinulle rajana.
              </Text>
              <Text>
                Ohjelmistokehitys muuttuu ja kasvaa kovaa vauhtia, sen myötä
                teknologioita ja ohjelmoitavia alustoja on monia. Onkin tärkeää
                osata yhdistää oikeat teknologiat ja kulloinkin vallitsevat
                trendit. Niitä yhdistämällä pystymme tarjoamaan sinulle
                monipuoliset ja tehokkaat ohjelmistokehitys ratkaisut. Meidän
                ammattitaitomme perustuu moderneihin ja johtaviin ratkaisuihin
                ohjelmistosuunnittelussa. Me tulemme ratkaisemaan kaikkien
                asiakkaiden ongelmat, olivatpa ne kuinka isoja tai pieniä
                tahansa – sataprosenttisella tyytyväisyystakuulla!
              </Text>
            </div>
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 mt-6 lg:mt-0">
              <StaticImage
                src="../images/3-right-trans-color.svg"
                alt="Mobiilikehitys elementtejä"
              />
            </div>
          </div>
        </DotSection>
      </section>

      <section id="customer-terms" className="bg-gray-800 text-center">
        <Container>
          <Title tag="h2" textColor="text-white">
            Ohjelmistosuunnittelua asiakkaan ehdoilla
          </Title>
          <Text textColor="text-white">
            Ohjelmistokehitys lähtee asiakkaan tarpeesta. Meillä tärkeintä on
            ymmärtää asiakkaan tarve ohjelmistolle, mitä sillä halutaan
            saavuttaa ja miten sitä halutaan käyttää. Aluksi on mietittävä onko
            sinulla tarve tilaustyölle vai valmisohjelmistolle, sillä useissa
            tapauksissa pystymme hyödyntämään jo olemassa olevia pohjia, mikä
            tulee säästämään lopulta aikaa ja rahaa projekteissa. Tarvittaessa
            myös luomme ohjelmiston alusta alkaen.
          </Text>
        </Container>
      </section>

      <section id="stages">
        <Container>
          <Title tag="h2">Ohjelmistokehityksen vaiheet</Title>
          <Text>
            Jokaisen ohjelmistokehityksen vaiheet vaihtelevat ja saattavat mennä
            hieman eri järjestyksessä, mutta yleensä niitä kuitenkin voidaan
            lähteä kehittämään kolmessa vaiheessa.
          </Text>
          <Text>
            Ensimmäisenä kehitysvaiheena on versio joka on prototyyppi. Todella
            yksinkertainen, pelkistetty ja karu versio, joka osoittaa
            ohjelmiston toimivuuden.
          </Text>
          <Text>
            Seuraavana kehitysvaiheena on tämän prototyypin parantaminen. MVP
            (pienin mahdollinen julkaisuvalmis ohjelmisto) on suunnattu siihen,
            että ohjelmisto pystyy keräämään palautetta tuotekehitystä varten
            sekä ohjelmisto pystyy tyydyttämään ensimmäisten asiakkaiden
            tarpeet.
          </Text>
          <Text>
            Kolmantena vaiheena prototyypistä hiotaan palautteiden ja
            kehittämisen jälkeen valmis ohjelmisto asiakkaiden/yrityksen
            tarpeisiin.
          </Text>
          <Text>
            Hyvä lopputulos ei synny sillä, että asiakas työntää ohjelmistoidean
            suoraan ohjelmistotalolle. Ohjelmistokehityksessä on tärkeää, että
            asiakkaan ja ohjelmistokehittäjän välillä on avoin vuoropuhelu,
            tiivis yhteistyö sekä tiivis yhteydenpito – tällä tavalla saamme
            varmistettua toimivan ohjelmiston sekä tyytyväisen asiakkaan.
          </Text>
        </Container>
      </section>

      <section id="techiniques" className="bg-gray-800">
        <Container>
          <div className="lg:text-center">
            <Title tag="h2" textColor="text-white">
              Käyttämämme tekniikat
            </Title>
            <Text textColor="text-white">
              Laadukas ohjelmistosuunnittelu tarvitsee paljon enemmän kuin
              pelkästään tietyn kielen tai välineen osaamista.
              Ohjelmistosuunnittelu on projektinhallintaa, kykyä perehtyä uusiin
              tekniikoihin, ongelmanratkaisukykyä, sinnikkyyttä, toimialan
              tuntemusta sekä halua kuunnella ja palvella asiakasta!
            </Text>
          </div>
          <div className="mt-10">
            <div className="lg:text-center">
              <Text textColor="text-white">
                Me käytämme ohjelmistosuunnittelussa muun muassa seuraavia
                teknologioita:
              </Text>
            </div>
            <div className="grid lg:grid-cols-3">
              <div className="mt-5">
                <SubTitle tag="p">Web:</SubTitle>
                <div>
                  <BasicList>
                    <li className="text-white">
                      HTML, CSS, Java, Node.js, PHP, React.js,Python, Ruby, Ruby
                      on Rails, WordPress, Laravel, Spring, Vue.js, Backbone,
                      Meteor, AngularJS ja JQuery
                    </li>
                  </BasicList>
                </div>
              </div>
              <div className="mt-5">
                <SubTitle tag="p">Mobiili:</SubTitle>
                <div>
                  <BasicList>
                    <li className="text-white">
                      Java, Swift, React Native ja Cordova
                    </li>
                  </BasicList>
                </div>
              </div>
              <div className="mt-5">
                <SubTitle tag="p">Muut:</SubTitle>
                <div>
                  <BasicList>
                    <li className="text-white">Python, Go, Linux</li>
                  </BasicList>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section id="software-and-open-source">
        <Container>
          <div className="relative lg:grid lg:grid-cols-2 lg:gap-x-8">
            <div>
              <Title tag="h2">Ohjelmistoprojektit ja avoin lähdekoodi</Title>
              <Text>
                Ohjelmistoprojektit ovat useimmiten tai miltein aina valmistettu
                avoimen lähdekoodin moduulien päälle. Tämän ansiosta ne ovat
                edullisempia kuin esimerkiksi ohjelmistolisenssejä vaativat
                ohjelmistot.
              </Text>
              <Text>
                Pystymme takaamaan kilpailukykyiset hinnat avointa lähdekoodia
                käyttämällä, mutta silti mikään ohjelmisto ei ole avoimesti
                suoraan saatavilla. Meiltä löytyy tarvittava ammattitaito ja
                osaaminen, että pystymme hyödyntämään avoimia lähdekoodeja ja
                sitä kautta räätälöidä asiakkaalle ohjelmisto.
              </Text>
              <Text>
                Useissa tapauksissa avoin lähdekoodi on myös luotettavampi kuin
                suljettu lähdekoodi, sillä avointa lähdekoodia voidaan käydä
                läpi julkisesti kehitysyhteisössä ja sitä voidaan myös itse
                parannella ohjelmistoon sopivaksi.
              </Text>
            </div>
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 mt-6 lg:mt-0">
              <StaticImage
                src="../images/2-left-trans-color.svg"
                alt="Applikaatio layoutit"
              />
            </div>
          </div>
        </Container>
      </section>

      <section id="agile-software-development" className="bg-gray-800">
        <Container>
          <Title tag="h2" textColor="text-white">
            Ketterä ohjelmistokehitys
          </Title>
          <Text textColor="text-white">
            Ketterä kehitys pystyy takaamaan nopean reagoinnin vaadittaviin
            muutoksiin esimerkiksi Scrum-mallia hyödyntäen. Kehityksessä
            ohjelmisto pyritään jakamaan pieniin ja lyhyisiin iteraatioihin tai
            osaprojekteihin, millä voidaan minimoida riskejä. Jokaisen projektin
            osa pystytään tuottamaan nopealla aikataululla ja sen lisäksi se
            sisältää uusien toimintojen julkaisemiseen vaadittavat tehtävät.
          </Text>
          <Text textColor="text-white">
            Jotta ohjelmisto olisi käyttö- ja testauskelpoinen, pyrimme tekemään
            ohjelmistosta julkaisuvalmiin jokaisen osan jälkeen mikä siihen
            lisätään. Näin ollen sinne pystytään lisäämään uusia ominaisuuksia,
            sekä muokkaamaan vanhoja osia.
          </Text>

          <Text textColor="text-white">
            Ohjelmistoa suunniteltaessa muutoksia tulee yleensä aina, tästä
            syystä ketterä kehitys on usein helpompi, halvempi ja joustavampi
            tapa tuottaa ohjelmistoja.
          </Text>
          <div className="mt-5">
            <Text textColor="text-white">
              Tästä löydät listattuna muutamia hyötyjä ketterästä kehityksestä:
            </Text>
            <BasicList>
              <li className="text-white">Se on kustannustehokasta</li>
              <li className="text-white">
                Automatisoi prosesseja, mitä tehtiin ennen käsin
              </li>
              <li className="text-white">
                Ketterä kehitys ratkaisee asiakaskohtaisen tarpeen, jos siihen
                ei ole saatavilla valmisohjelmistoa
              </li>
              <li className="text-white">
                Jatkokehitys mukautuu uusiin ideoihin sekä liiketoiminnan
                tarpeisiin
              </li>
              <li className="text-white">
                Mahdollista integroitua esimerkiksi maksamisen ratkaisuihin tai
                toiminnanohjaukseen
              </li>
              <li className="text-white">
                Avointa lähdekoodia hyödyntämällä et joudu sitoutumaan
                ainoastaan yhteen ohjelmistoon / it-toimittajaan
              </li>
              <li className="text-white">
                Kilpailun syntyessä tuottaa innovatiivisia toimintamalleja
              </li>
              <li className="text-white">
                Mahdollista hyödyntää open source-komponentteja
              </li>
            </BasicList>
          </div>
        </Container>
      </section>

      <section id="maintenance">
        <Container>
          <Title tag="h2">Projektin jälkeinen ylläpito</Title>
          <Text>
            Kun ohjelma on julkaistu ja käytössä, ei tule unohtaa sen taustalla
            pyörivää ohjelmistoa. Ohjelmiston on tärkeää pysyä toiminnassa, eli
            ajan tasalla ja tasaisesti huollettuna. Me tarjoamme
            valmistamillemme ohjelmille ylläpidon teknologioista riippumatta.
            Alustasta riippuen pystymme myös tekemään päivityksiä paikallisesti
            tai etänä. Tarpeen vaatiessa autamme serverin päivittämisessä ja sen
            saattamisesta ajan tasalle.
          </Text>
        </Container>
      </section>
    </Layout>
  )
}
